@charset "UTF-8";
/*
=======================================
    Reset CSS
=======================================
*/
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img,
ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr,
th, td, article, aside, canvas, details, figcaption, figure, footer, header, main, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section {
  display: block;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
}

body {
  background-color: #fff;
  color: #393939;
  font-family: "Microsoft YaHei", "微软雅黑", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", Arial, sans-serif;
  position: relative;
  font-size: 14px;
  overflow-x: hidden;
  height: 100%;
}

input, textarea {
  -webkit-appearance: none;
  outline: none;
  padding: 2px;
  border: none;
}

textarea {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif !important;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
  /*margin: auto;*/
}

a, img {
  /*阻止长按页面，弹出菜单的方法。*/
  -webkit-touch-callout: none;
}

ul, ol {
  list-style-type: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

img, input, select, textarea {
  vertical-align: middle;
}

textarea {
  resize: none;
}

a {
  color: #333;
  text-decoration: none;
}

section {
  position: relative;
}

.container {
  /*padding-top: 70px;*/
  width: 100%;
}

.content {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
}

@media screen and (max-width: 1080px) {
  .content {
    width: 100%;
    margin: 0 auto;
    height: 100%;
  }
}
/*
=========================================
    Utilities
=========================================
*/
/* 水平對齊
---------------------------------*/
.ta-l {
  text-align: left;
}

.ta-r {
  text-align: right;
}

.ta-c {
  text-align: center;
}

.mr {
  margin-right: 10px;
}

.ml {
  margin-left: 10px;
}

/* 浮動／清除浮動
---------------------------------*/
.fl {
  float: left;
}

.fr {
  float: right;
}

.fl-img {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

.fr-img {
  float: right;
  margin-left: 10px;
  margin-bottom: 10px;
}

.clear {
  clear: both;
}

/* clearfix */
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.clearfix {
  zoom: 1;
}

.block-btn {
  width: 100%;
}

/* flex */
.flex-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex-align-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.hide {
  display: none;
}

.show {
  display: block;
}

@media screen and (max-width: 1080px) {
  .hidden-sm {
    display: none !important;
  }
}
